/* uncomment this block to center align the top nav */
/* http://stackoverflow.com/questions/19733447/bootstrap-navbar-with-left-center-and-right-aligned-items */
@media (min-width: $screen-md-min) {
  .navbar-nav {
    width: 100%;
    text-align: center;
  }
  .navbar-nav > li {
    float: none;
    display: inline-block;
  }
  .navbar-nav > li.navbar-right {
    float: right !important;
  }
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: #fff;
	  // background-color: $brand-green;
  }
}


.nav > li > a
{
	padding-left: 9px;
	padding-right: 9px;

	@media (min-width: $screen-md-min) {
	    padding: $nav-link-padding;
	}

	&:hover, 
	&:focus
	{
		background-color: #aed5dd;	
	}
}

.navbar-default
{
	padding-top: 8px;
	padding-bottom: 8px;
	background-color: $highlight-bg-color;

	.navbar-toggle
	{
		border-color: $highlight-link-color;

		&:hover, 
		&:focus
		{
			background-color: $primary-bg-color;
		}	

		.icon-bar
		{
			background-color: $highlight-link-color;
		}
	}

	.navbar-nav 
	{
		> li > a
		{
			font-family: $copyfont;
			font-weight: 500;			
			font-size: 14px; /* TODO SET TO 21px at LARGE DESKTOP */			
			color: $highlight-link-color;

			&:hover, 
			&:focus
			{
				color: $highlight-link-color-hover;
			}
		}

		@media (max-width: $grid-float-breakpoint-max) {
		  // Dropdowns get custom display when collapsed
		  .open .dropdown-menu {
		    > li > a {
		      color: $highlight-link-color;
		    }
		  }
		}
	}
}

.navbar-social > li > a
{
	@media (min-width: $screen-sm-min) {
	    padding-top: 11px;
		padding-bottom: 11px;
	}

	@media (min-width: $screen-md-min) {
	    padding-top: 7px;
		padding-bottom: 7px;
	}	
}