.sidebar
{
	.widget_archive,
	.widget_recent_entries
	{
		ul
		{
			@extend .list-unstyled;
			margin: 15px;

			li
			{
				margin-bottom: .75em;
			}
		}
	}

	p
	{
		font-size: 26px;
		line-height: 60px;
		margin-bottom: 0;
	}
    
    // fix contact form in sidebar
    .form-group-last {
        margin-bottom: 0;
    }
}