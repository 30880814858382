.snake {
	border: solid 7px $brand-dark-colour;
	border-radius: 5px;
}

.sidebar .widget,
.contentbox
{
	@extend .snake;
	margin-bottom: 15px;

	h3
	{
		margin: 0;
		padding: 10px 15px 0;
	}

	.textwidget,
	.inner
	{
		padding: 15px;
	}
}

.alignright
{
	@extend .snake;
	clear: both;
}