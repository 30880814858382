
main.main {
    margin-bottom: 3em;
}

.main h2,
.main h3
{
	text-align: left;
	margin-bottom: 1em;
	margin-top: 1.5em;
}

.main h2
{
	font-size: 20px;
}

.main h2:first-child
{
	margin-top: 0;
}

.slidercontainer
{
	@extend .margintail;
}


.social-icon
{
	color: #DCCEB5;
	display: inline-block;
	width: 25px;
	height: 43px;
	line-height: 43px;
	font-size: 24px;
}

.social-icon:hover,
.social-icon:active
{
	color: #525252;
}




// typography
/////////////
a
{
	color: $primary-link-color;
}

a:hover,
a:active,
a:focus
{
	color: $primary-link-color;
}

.btn-primary,
.comment-form input[type="submit"]
{
	background-color: $primary-link-color;
	border-color: $primary-link-border-color;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary,
.comment-form input[type="submit"]:hover,
.comment-form input[type="submit"]:focus,
.comment-form input[type="submit"].focus,
.comment-form input[type="submit"]:active,
.comment-form input[type="submit"].active,
.open > .dropdown-toggle.comment-form input[type="submit"]
{
	background-color: $primary-link-color-hover;
	border-color: $primary-link-border-color-hover;
}

// Active state
.dropdown-menu > .active > a {
  &,
  &:hover,
  &:focus {
    color: #fff;
	background-color: $primary-link-color-hover;
  }
}

h1, h2, h3, h4
{
	font-family: $headerfont;
}

h1
{
	font-size: 2.8em;
	margin-bottom: .8em;
	margin-top: 0;
	text-align: center;
	font-weight: bold;
}

h2
{
	margin-top: 0;
	font-size: 42px;
	text-align: center;
	margin-bottom: 60px;
}

body, p, li
{
	font-family: $copyfont;
	font-weight: 500;
	font-size: 17px;
}

.likebox-wrapper * {
   width: 100% !important;
}

.margintop
{
	margin-top: 40px;
}

.margintail
{
	margin-bottom: 40px;
}

.margintoptail
{
	@extend .margintop;
	@extend .margintail;
}

.toptail {
	margin-top: 20px;
	margin-bottom: 20px;	
}

.main h2,
.main h3
{
	text-align: left;
	margin-bottom: 1em;
	margin-top: 1.5em;
}

.main h2
{
	font-size: 20px;
}

.main h2:first-child
{
	margin-top: 0;
}

.logo
{
	@media (max-width: $screen-sm-max) {
		@include center-block;
	}
}

.contact-entries
{
	font-size: 20px !important;

	span
	{
		color: $main-link-color;
		margin-top: 8px;
		display:block;
	}

	li
	{
		margin-bottom: 8px;
	}

	ul,
	li:last-child
	{
		margin-bottom: 0;
	}

	a
	{
		color: $main-link-color;
		font-size: 1.2em;
		line-height: 1.2em;
		font-weight: bold;

		@media (min-width: $screen-sm-min) {
			font-size: 1.2em;
		}
	}
}

.nav > li > a
{
	padding-left: 9px;
	padding-right: 9px;

	@media (min-width: $screen-md-min) {
	    padding: $nav-link-padding;
	}

	&:hover,
	&:focus
	{
		background-color: #aed5dd;
	}
}

.navbar-default
{
	padding-top: 8px;
	padding-bottom: 8px;
	background-color: $highlight-bg-color;

	.navbar-toggle
	{
		border-color: $highlight-link-color;

		&:hover,
		&:focus
		{
			background-color: $primary-bg-color;
		}

		.icon-bar
		{
			background-color: $highlight-link-color;
		}
	}

	.navbar-nav
	{
		> li > a
		{
			font-family: $copyfont;
			font-weight: 500;
			font-size: 14px; /* TODO SET TO 21px at LARGE DESKTOP */
			color: $highlight-link-color;

			&:hover,
			&:focus
			{
				color: $highlight-link-color-hover;
				background-color: $highlight-bg-color-hover;
			}
		}

		@media (max-width: $grid-float-breakpoint-max) {
		  // Dropdowns get custom display when collapsed
		  .open .dropdown-menu {
		    > li > a {
		      color: $highlight-link-color;
		    }
		  }
		}
	}
}

.navbar-social > li > a
{
	@media (min-width: $screen-sm-min) {
	    padding-top: 11px;
		padding-bottom: 11px;
	}

	@media (min-width: $screen-md-min) {
	    padding-top: 7px;
		padding-bottom: 7px;
	}
}

.slidercontainer
{
	@extend .margintail;
}

/* blog */
article.post
{
	margin-bottom: 3em;
}

h2.entry-title
{
	margin-bottom: 0.5em;
}
/* end blog */


.bg-white
{
	background-color: #fff;
}

.bg-secondary-lowcontrast
{
	background-color: $secondary-bg-lowcontrast;
}

.bg-lightgrey
{
	background-color: #f9f9f7;
}

.bg-lightbrown
{
	background-color: #ebe0cc;
}

.bg-darkgrey
{
	background-color: #525252;
}

.bg-footer-brownbar
{
	border-bottom: 8px solid #b6ad9e;
}

.bg-blue h2,
.bg-blue p,
.bg-blue .testimonial-carousel-controls a
{
	color: #fff;
}

.img-borderline
{
	padding: 3px;
	border: 1px solid #dcceb5;
}

.list-headlines li
{
	margin-bottom: 0.5em;
}

.list-pipeseparator
{
	li
	{
	    padding-right: 0;

	    &:before
	    {
			content: "| ";
			padding-right: 5px;
		}

		&:first-child:before
		{
			content: "";
			padding-right: 0;
		}
	}
}

.social-icon
{
	color: #DCCEB5;
	display: inline-block;
	width: 25px;
	height: 43px;
	line-height: 43px;
	font-size: 24px;
}

.social-icon:hover,
.social-icon:active
{
	color: #525252;
}


.section
{
	padding-top: 50px;
	padding-bottom: 50px;
}

.section-logo
{
	padding-top: 20px;
	padding-bottom: 20px;
}

.section-innerheader
{
	padding-top: 20px;
	padding-bottom: 20px;
}

.section-welcome
{
	text-align: center;
}

.section-welcome p
{
	font-size: 1.3em;
}

.section-welcome p:last-child
{
	margin-bottom: 0;
}

.section-oversize h3
{
	font-size: 2.2em;
}

.section-oversize p
{
	font-size: 1.2em;
}

.section-columns img,
.section-columns h3
{
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.section-columns .btn
{
	text-transform: uppercase;
}

.columns-content
{
	margin-bottom: 1em;
}


// CLIENTS.LESS
main li
{
  margin-bottom: 1em;
}

body {
  padding-top: 70px;
}

/* slider */
ul.slide3 li {
  margin-bottom: 0.7em;
}

/* pest services page */
.pestgrid .pest
{
  @extend .margintail;
}

@media (min-width: $screen-sm-min) {
  .pestgrid .pest
  {
    float: left;
    width: 50%;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media (min-width: $screen-md-min) {
  .pestgrid .pest
  {
    float: left;
    width: 33%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* fix so wp admin bar doesn't clash with topnav */
body.admin-bar .navbar-fixed-top {
  top: 32px !important;
}


/* contact form 7 horizontal form markup */
.contactformhorizontal {
  background-color: $highlight-bg-color;
  padding-top: 20px;
  padding-bottom: 10px; /* 40px - 15px .form-group margin-bottom x2 */

  h3
  {
    color: #fff;
    text-align: center;
    padding-bottom: 20px;
  }

  label
  {
    color: #fff;
  }

  .wpcf7-submit
  {
    margin-top: 10px;
  }

  span.wpcf7-not-valid-tip
  {
    background-color: #a01d20;
    color: #fff;
    border-radius: 3px;
    margin-top: 3px;
    padding: 3px;
    font-size: smaller;
  }

  .wpcf7-response-output
  {
    background-color: #f7e700;
    border-radius: 3px;
    margin: 0;
  }
}

// PFW
nav.navbar
{
	background-color: #0053a1;
}

.hero {
	background-color: #0053a1;
}

.placeholder
{
	display: table;
	width: 100%;
}

.placeholder div
{
	background-color: #023A6F;
	display:table-cell;
	text-align: center;
	vertical-align: middle;
	color: #fff;
}

.placeholder p.h2 {
	margin: 2em 0;
	padding: 0;
	font-size: 30px;
	font-family: $copyfont;
	font-weight: normal;
}

.bg-blue
{
	background-color: #0053a1;
}

.telephonecontainer {
	padding: 10px 0;
	margin: 10px 0;
	border: 1px solid #0053a1;
}

.coveragemap {
	border: 1px solid #0053a1;
}

.hero-image {
	margin-bottom: 1em;
	border: 1px solid #0053a1;
}

    .logo {
        display:block;
        margin-left: auto;
        margin-right: auto
    }

@media (min-width: 992px) {
	header {
		margin-bottom: 2em;
	}
}

.logocol p {
	margin-bottom: 0;

	&.title {
		margin-top: 0;
		font-size: 36px;
	}
}

footer .row {
	margin-top: 10px;
	margin-bottom: 10px;
}