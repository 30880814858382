.padded {
    padding: 15px;
}

.margintop
{
	margin-top: 40px;
}

.margintopbig
{
    margin-top: 40px;
    @media (min-width: $screen-sm-min) {
        margin-top: 70px;
    }
}

.margintail
{
	margin-bottom: 40px;
}

.margintoptail
{
	@extend .margintop;
	@extend .margintail;
}

.nomargintail {
    margin-bottom: 0;
}

.nopaddingtail {
    padding-bottom: 0 !important;
}

.img-borderline
{
	padding: 3px; 
	border: 1px solid #dcceb5;
}

.list-chevron {
  list-style-type: none;
  
  li a {
    padding-left: 1.3em;
    
    &:before {
      content: "\f101"; /* FontAwesome Unicode */
      font: 14px FontAwesome;
      display: inline-block;
      margin-left: -1.3em; /* same as padding-left set on li */
      width: 1.3em; /* same as padding-left set on li */
    }
  }
}

.list-twocol {
	li
	{
		@include make-sm-column(6);
    padding-left: 0;
    padding-right: 0;
	}
}

.list-pipeseparator
{
	li
	{
	  padding-right: 0;

	  &:before {
			content: "| ";
			padding-right: 5px;
		}

		&:first-child:before {
			content: "";
			padding-right: 0;
		}
	}
}

// break away from the crazy default footer line height
.row-footer-text p {
    line-height: inherit;
}

// keep headings aligned
main.main h3:first-child {
    margin-top: 0;
}

// fix busted design with testimonials listing view
// wordpress was inserting empty <p> tags that messes up the design
/*
.strong-view-id-1 .testimonial p:last-child {
    display: none;
}
*/

// fix the facebook widget width
.likebox-wrapper * {
   width: 100% !important;
}