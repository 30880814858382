// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}


// contact form 7
div.wpcf7 img.ajax-loader {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
}

div.wpcf7 .wpcf7-not-valid-tip {
    background: #E6E6E6;
    padding: 10px;
}

div.wpcf7-mail-sent-ok {
    border: none;
    color: #fff;
    background-color: #5C7679;
    display: inline-block;
}

div.wpcf7-response-output {
    margin: 0;
}