/* element - portal hover box */
/* css from https://codepen.io/ArnaudBalland/pen/vGZKLr?editors=1100 */
.soka-portal-hover-box {
    position: relative;
    margin: auto;
    overflow: hidden;
    border-radius: 5px;
  }
  
  .soka-portal-hover-box .soka-hoverbox-overlay {
    background: rgba(0,0,0,0.7);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  .soka-portal-hover-box:hover .soka-hoverbox-overlay{
    opacity: 1;
  }
  
  .soka-hoverbox-image{
    width: 100%;
  }
  
  .soka-hoverbox-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  .soka-portal-hover-box:hover .soka-hoverbox-details{
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  
  .soka-portal-hover-box a{
    color: #fff;
  }
  
  .soka-hoverbox-icon {
    /*color: #fff;*/
    font-size: 2.5em;
    font-weight: bold;
  }
  
  .soka-fadeIn-bottom{
    top: 80%;
  }
  
  .soka-fadeIn-top{
    top: 20%;
  }
  
  .soka-fadeIn-left{
    left: 20%;
  }
  
  .soka-fadeIn-right{
    left: 80%;
  }
  /* END element - portal hover box */