.contact-entries
{
    @extend .text-center;
    
    @media (min-width: $screen-md-min) {
        text-align: left;
    }

	span.type
	{
        @media (max-width: $screen-sm-max) {
            display: none;
        }
        
		color: $contact-entries-label;	
		margin-top: 8px;
		display:block;		
	}

	li
	{
		margin-bottom: 8px;
	}	

	ul,
	li:last-child
	{
		margin-bottom: 0;
	}

	a
	{
		color: $contact-entries-link;	
        font-size: 1.2em;
        padding: 4px;
		font-weight: bold;

		@media (min-width: $screen-sm-min) {
            font-size: 1.2em;
            line-height: 1.2em;
            padding: inherit;
		}
        
        &.tel{
            color: $brand-darkred;
            font-weight: bold;
        }
        
        &.email {
            font-size: 1em;
        }
	}
}

@media (min-width:$screen-md-min) {
  header .contact-entries {
    text-align: left;

    li {
      font-size: 1.1em;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 1.1em;
      }
    }
  }
}

@media (min-width: $screen-lg-min) {
  header .contact-entries {
    li{
      font-size: 1.2em;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 1.2em;
      }
    }
  }
}

.bg-blue .contact-entries span {
	color: #fff;
}

.bg-blue .contact-entries a {
	color: #fff;
}