// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// colours
$brand-blue:                        #0053a1;
$brand-red:                         #E30923;
$brand-darkred:                       #c7453c;
//$brand-green:                       #22a309;
//$brand-darkblue:                    #0d5480;
$brand-primary:                     $brand-blue;

$primary-link-color:				$brand-darkred;
$primary-link-border-color:			darken($primary-link-color, 15%);
//$primary-link-color-hover:		    lighten(#1b1d8a, 15%);
//$primary-link-border-color-hover:	darken($highlight-bg-color, 15%);

$primary-bg-color: 					#eee;


$highlight-bg-color: 				#1b1d8a;
$highlight-link-color:				#fff;
$highlight-link-color-hover:		$primary-link-color;

$secondary-bg-lowcontrast:			#d6f0ff;

// FROM LESS
$main-link-color:					        $brand-darkred;
$primary-link-color:				        #89929B;
$primary-link-border-color:                 darken($primary-link-color, 10%);
$primary-link-color-hover:                  $brand-darkred;
$primary-link-border-color-hover:	        darken($primary-link-color-hover, 10%);
$primary-bg-color: 					        #eee;
$brand-dark-colour:                         #5D666F; // dark grey from logo
$highlight-bg-color: 				        $brand-blue;
$highlight-link-color:				        #fff;
$highlight-bg-color-hover:                  darken($highlight-bg-color, 15%);
$highlight-link-color-hover:                #fff;
$secondary-bg-lowcontrast:			        #9EB4C2;

// font sizes
$font-size-before-mobile: 10px;
$font-size-before-normal: 14px;
$font-size-text-mobile: 14px;
$font-size-text-normal: 18px;

// typography
// Dont forget: If you specify a Google Font below, you need to register it in .lib/setup.php/GOOGLE_FONTS
$headerfont: "Francois One", "Open Sans", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", Verdana, sans-serif;
$copyfont: Raleway, Georgia, Times, 'Times New Roman', serif;
$link-color:                        $brand-darkred;
$link-hover-color:                  darken($link-color, 10%);


// bootstrap overrides

// uncomment for custom navbar break
// note: can't use $screen-XX-min variables as BS not included yet
// $grid-float-breakpoint: 992px; 

$navbar-default-link-hover-color:	#000;
$navbar-default-link-active-color:	#000;
/*
$navbar-height: 80px;
$navbar-padding-vertical: 17px;
$navbar-padding-horizontal: 15px;

$navbar-default-bg: $brand-darkergreen;
$navbar-default-border: transparent;
$navbar-default-link-hover-color:	#000;
$navbar-default-link-active-color:	#000;

$navbar-default-toggle-icon-bar-bg: #fff;
$navbar-default-toggle-hover-bg: darken($navbar-default-bg, 12.5%);
*/


// testimonials-speechbubble overrides
$speechbubble-quote-backgroundcolor:	$highlight-bg-color;
$speechbubble-quote-textcolor:			$highlight-link-color;
$speechbubble-attrib-textcolor:			$highlight-bg-color;

// header contact styles
$contact-entries-label:                 $brand-red;
$contact-entries-link:                  $brand-red;