
.section-footer
{
	padding-top: 40px;
	padding-bottom: 40px;	


	h4, li, a
	{
		color: $highlight-link-color;
	}

	a
	{
		&:hover,
		&:focus
		{
			color: $highlight-link-color-hover;
			text-decoration: underline;
		}
	}

	.widget_recent_entries ul
	{
		@extend .list-unstyled;
	}

	ul#menu-footer-menu
	{
		@include make-row();
	}

	ul#menu-footer-menu li
	{
		@include make-sm-column(6);
	}

	.footer-contacts li
	{
		margin-bottom: 1em;
	}

	.row-footer-text p
	{
	  line-height: 1.5em;
	}	
}

.section-footer-copyright
{
	p, a
	{
		font-family: $copyfont;
		color: #525252;
	}
}